import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Image from 'components/help/helpImage';

import logo from 'img/help/integrations/livechat.png';
import livechat1 from '../../img/help/livechat/livechat1.png';
import livechat2 from '../../img/help/livechat/livechat2.png';
import livechat3 from '../../img/help/livechat/livechat3.png';
import livechat4 from '../../img/help/livechat/livechat4.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is LiveChat?</SubTitle>
      <p>
        <strong>LiveChat is a live chat web application provider</strong>. You can use it to provide
        your customers with support or to guide your visitors through buying process.
      </p>
      <SubTitle className="mt-5">How does this integration work?</SubTitle>
      <p>
        This integration automatically adds links for every new conversation to session replays from
        LiveSession to LiveChat dashboard.{' '}
        <strong>
          Thanks to this solution, you can open recordings of a specific user directly from chat
          screen
        </strong>
        .
      </p>
      <Image src={livechat1} lazy alt="LiveChat integration" title="Conversation" />
      <p>
        Every time a new session will start, our code will send an event to LiveChat with a link to
        session replay.
      </p>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <p>
          For the integration to work, you need to have both LiveChat and LiveSession installed on
          your website. If you haven&apos;t installed our tracking code yet, check{' '}
          <a
            href="/help/how-to-install-livesession-script/"
            target="_blank"
            rel="noopener noreferrer"
          >
            this article
          </a>{' '}
          for instructions.
        </p>
      </div>
      <p>
        Visit LiveChat in{' '}
        <a href={`${process.env.GATSBY_HOST}/integrations/livechat/`}>integrations page</a> to
        install. Then click <strong>Install now</strong> to confirm:
      </p>
      <Image
        src={livechat2}
        lazy
        alt="LiveChat integration"
        title="LiveChat in integrations page"
      />
      <p>
        You will be redirected to LiveChat. Authorize <strong>LiveSession</strong> access to your{' '}
        <strong>LiveChat</strong> account:
      </p>
      <Image src={livechat3} lazy alt="LiveChat integration" title="Authorize LiveChat access" />
      <p>
        After granting access, you will be redirected back to <strong>LiveSession</strong>. You’ll
        see the LiveChat integration on the list:
      </p>
      <Image src={livechat4} lazy alt="LiveChat integration" title="LiveChat is installed" />
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and LiveChat integration',
  metaDescription: 'Send LiveSession recordings to your LiveChat account',
  canonical: '/help/livechat-integration/',
  logoStyle: { width: 120 },
  integrationLink: '/app/install/livechat',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
